import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("./views/Dashboard.vue"),
  },
  {
    path: "/statistics",
    component: () => import("./views/Statistics.vue"),
  },
  {
    path: "/statistics_test_grouping_data",
    component: () => import("./views/Statistics2.vue"),
  },
  {
    path: "/campaigns",
    component: () => import("./views/Campaigns/Index.vue"),
  },
  {
    path: "/extra-monetization",
    component: () => import("./views/ExtraMonetization/Index.vue"),
  },
  {
    path: "/news",
    component: () => import("./views/News.vue"),
  },
  {
    path: "/faq",
    component: () => import("./views/Faq.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("./views/Profile/Index.vue"),
  },
  {
    path: "/tools",
    name: "tools",
    component: () => import("./views/Tools/Index.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("./views/Privacy.vue"),
  },
  {
    path: "/finances",
    component: () => import("./views/Finances/Index.vue"),
  },
  {
    path: "/payout",
    component: () => import("./views/UpdatePayout.vue"),
  },
  {
    path: "/verify-email",
    component: () => import("./views/VerifyAndUpdateEmail.vue"),
  },
  {
    path: "/error/401",
    component: () => import("./views/Error401.vue"),
  },
  {
    path: "/error/404",
    component: () => import("./views/Error404.vue"),
  },
  {
    path: "/error/:code(.+)",
    component: () => import("./views/Error500.vue"),
  },
  {
    path: "/:path(.*)",
    redirect: "/statistics",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
