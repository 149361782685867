export default {
  mounted(el: any, binding: any) {
    el.__directive_copyToClipboard = (e: any) => {
      if (!navigator.clipboard) {
        return;
      }
      navigator.clipboard.writeText(binding.value);

      const crd = el.getBoundingClientRect();
      const x = crd.x;
      const y = crd.y;
      $("body").append(
        `<div
          class="success-copy-tooltip"
          style="top: ${y + 26}px; left: ${x - 50}px"
        >
          Скопировано!
        </div>`
      );
      setTimeout(() => {
        $(document).find(".success-copy-tooltip").remove();
      }, 2000);
    };
    el.addEventListener("click", el.__directive_copyToClipboard);
  },
  beforeUnmount(el: any) {
    el.removeEventListener("click", el.__directive_copyToClipboard);
    delete el.__directive_copyToClipboard;
  },
};
