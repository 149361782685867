<template>
  <router-view />
</template>

<style lang="scss">
.input__error {
  font-size: 14px;
  line-height: 16px;
  color: #ee423d;
}
</style>
