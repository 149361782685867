import Rollbar from "rollbar";

let rollbar: Rollbar | null = null;

if (process.env.NODE_ENV === "production") {
  rollbar = new Rollbar({
    accessToken: "1d516a94cc2f4e01aa6c407df77726f3",
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: "production-frontend-private",
  });
}

export default rollbar;
