import $ from "jquery";
const global = window as any;
// needed for fancybox
global.jQuery = global.$ = $;

import { createApp } from "vue";
import axios from "axios";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import rollbar from "./rollbar";

import clickOutside from "./modules/click-outside";
import copyToClipboard from "./modules/copy-to-clipboard";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      location.href = process.env.VUE_APP_AFFILIATE_SITE_ENTRANCE;
      return;
    }
    return Promise.reject(error);
  }
);

const app = createApp(App)
  .use(store)
  .use(router)
  .directive("click-outside", clickOutside)
  .directive("copy-to-clipboard", copyToClipboard);

if (rollbar) {
  app.config.errorHandler = function (err) {
    rollbar!.error(err as any);
    throw err;
  };
}

app.mount("body");
