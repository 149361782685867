import { createStore } from "vuex";
import rollbar from "../rollbar";
interface User {
  id: null | number;
  name: string;
  email: string;
  phone: string;
  telegram: string;
}

interface Notification {
  type: "success" | "error";
  message: string;
  autoClose: boolean;
}

interface DataModel {
  user: User | null;
  notification: Notification | null;
}

export default createStore<DataModel>({
  state: {
    user: null,
    notification: null,
  },
  mutations: {
    setUser(state, user: User) {
      state.user = user;
      if (rollbar && user && user.id) {
        rollbar.configure({
          payload: {
            person: user,
          },
        });
      }
    },
    setNotification(state, notification: Notification) {
      state.notification = notification;
    },
  },
  actions: {},
  modules: {},
});
